import React, { useEffect, useState } from "react";
import "../styles/template.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo-black-2.png";
import secondaryImage from "../assets/images/swageazy-mascot-new-Logo.png";
import axios from "axios";

export const Redemption = () => {
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const [templateData, setTemplateData] = useState({
    backgroundColor: "",
    logoImageUrl: "",
    secondaryImageUrl: "",
    heading: "",
    content: "",
    buttonColor: "",
    has_multiple_choice: "",
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  let params = { token: token };

  useEffect(() => {
    if (token === null || token === "" || token === undefined) {
      navigate("/");
      setTemplateData({
        logoImageUrl: logo,
        secondaryImageUrl: secondaryImage,
      });
      setLoading(false);
      return;
    }
    getTemplateIdFromCoupon();
  }, []);

  function getTemplateIdFromCoupon() {
    axios
      .get(
        `${process.env.REACT_APP_URL}/v4/customer/redemption/template?token=${token}`
      )
      .then((response) => {
        setTemplateData({
          backgroundColor:
            response.data.result.page_background_color ||
            response.data.result.pageBackgroundColor,
          logoImageUrl:
            response.data.result.logoImage || response.data.result.logoImage,
          secondaryImageUrl:
            response.data.result.secondary_image_url ||
            response.data.result.secondaryImage,
          heading: response.data.result.heading,
          content: response.data.result.content,
          buttonColor:
            response.data.result.button_color ||
            response.data.result.buttonColor,
          has_multiple_choice: response.data.result.has_multiple_choice,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setTemplateData({
          logoImageUrl: logo,
          secondaryImageUrl: secondaryImage,
        });
      });
  }

  function handleNavigation() {
    if (token === "null" || token === undefined || token === null) {
      navigate("/");
      return;
    } else {
      if (templateData.has_multiple_choice === 0) {
        navigate({
          pathname: "/redemption/checkout",
          search: `?${createSearchParams(params)}`,
        });
      } else {
        navigate({
          pathname: "/redemption/multi/packs",
          search: `?${createSearchParams(params)}`,
        });
      }
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-indicator-admin"></div>
      ) : (
        <div
          id="container"
          className="container-fluid"
          style={{ backgroundColor: templateData.backgroundColor || "#2c4c93" }}
        >
          <ToastContainer />

          <div className="container-fluid" id="redemption-demo-page" style={{}}>
            <div className="row pt-2">
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12"></div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="d-flex justify-content-start">
                  <img
                    id="logo"
                    className="img-fluid logo-img"
                    src={
                      templateData != null ? templateData.logoImageUrl : logo
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="row d-flex align-items-center p-3 outer-container"
              id="outer-container"
            >
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12"></div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 pt-5">
                    <p className="heading mobileScreenPadding">
                      {templateData.heading ||
                        "Welcome to Swageazy Gift Redemption!"}
                    </p>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <p className="below-heading-content">
                      {templateData.content ||
                        "Click below to redeem your gift :)"}
                    </p>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 desktopViewButton">
                    <button
                      onClick={() => handleNavigation()}
                      style={{ backgroundColor: templateData.buttonColor }}
                      className="p-3 redeem-swag-btn"
                    >
                      Grab Your Swag
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12"></div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center">
                <img
                  style={{ height: "300px" }}
                  id="mainimage"
                  className="img-fluid"
                  src={
                    templateData != null
                      ? templateData.secondaryImageUrl
                      : secondaryImage
                  }
                  alt=""
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-5 mobileViewButton">
                <button
                  onClick={() => handleNavigation()}
                  style={{ backgroundColor: templateData.buttonColor }}
                  className="p-3 redeem-swag-btn"
                >
                  Grab Your Swag
                </button>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mobileFooter text-center">
                <a
                  href="https://swageazy.com/"
                  target={"_blank"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p className="heading-5">© 2024, Powered by Swageazy</p>
                </a>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 footer text-center">
              <a
                href="https://swageazy.com/"
                target={"_blank"}
                style={{ textDecoration: "none", color: "white" }}
              >
                <p className="heading-5">© 2024, Powered by Swageazy</p>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
